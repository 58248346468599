@import '../../../CommonUI/styles.scss';

.others {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  height: auto;
  padding-top: 50px;
  display: flex;
  flex-flow: column nowrap;
  //justify-content: center;
  //align-items: center;
  //background-color: aqua;
  //background-image: url('../../../CommonUI/img/backgrounds/bg-white-min.png');
  //background-size: cover;
  //background-repeat: no-repeat;
  p {
    //color: $primary;
    //font-weight: $font-weight-bold;
    font-size: $font-size-n4;
  }
}

.project {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  padding-top: 50px;
  .project__title {
    hr {
      width: 40%;
      margin-bottom: 30px;
    }
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    p {
      width: 60%;
      text-align: center;
      font-size: $font-size-n9;
      font-family: $font-style-n1;
      font-weight: $font-weight-normal;
      padding: 10px;
      line-height: 30px;
      span {
        color: $primary;
      }
    }
  }

  .project__body {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5%;
    .project-body__img {
      max-width: 40%;
      //padding: 5%;
    }

    .project-body__box {
      width: 40%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      .project-box__comment-one {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        .project-box__line {
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-start;
          align-items: center;
          //width: 10px;
          //min-height: 300px;
          //padding: 0px 50px 0px 0px;
          img {
            max-width: 50px;
            //margin-top: 10px;
            //margin-bottom: 20px;
          }
          hr {
            height: 240px;
            width: 0.5px;
            border-width: 0;
          }
        }

        .project-box__line-two {
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
          width: 90%;
          p {
            text-align: left;
          }

          .comment_one {
            font-size: $font-size-n7;
            line-height: 45px;
            font-family: $font-style-n1;
            font-weight: $font-weight-normal;
            color: $primary;
          }

          .comment_two {
            width: 100%;
            background-color: $primary;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            align-self: center;
            margin: 0px;
            padding: 10px 0px;
            p {
              font-size: $font-size-n5;
              font-family: $font-style-n0;
              font-weight: $font-weight-normal;
              color: $white;
              text-align: center;
              line-height: 40px;
              margin: 0px !important;
              padding: 0px !important;
            }
          }
        }
      }

      .comment_three {
        font-size: $font-size-n3;
        font-family: $font-style-n0;
        font-weight: $font-weight-normal;
        margin-top: 40px;
        color: $white;
        font-size: $font-size-n3;
        text-align: left !important;
      }

      /*       p {
        text-align: start;
        margin-bottom: 20px;
      }

      .slide {
        width: 50%;
        padding: 10%;
      }

*/
    }
  }
}

.project_photos {
  width: 100%;
  padding-top: 50px;
  overflow: hidden;

  .project-photos__title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    padding-bottom: 50px;
    p {
      font-family: $font-style-n1;
      font-weight: $font-weight-normal;
      font-size: $font-size-n9;
    }
    hr {
      width: 30%;
      margin-bottom: 30px;
    }
  }
  .project-photos__title--green {
    color: $primary;
  }

  .project-photos__content {
    display: flex;
    flex-flow: row wrap;
    max-height: 200vh;
    overflow: hidden;

    .photo__one {
      img {
        min-width: 100%;
        max-height: 100vh;
        object-fit: cover;
      }
    }

    .photo__two {
      max-width: 50%;
      min-width: 50%;
      padding-top: 5px;
      padding-right: 2px;
      overflow: hidden;
      img {
        min-width: 100%;
        max-height: 100vh;
        min-height: 100%;
        object-fit: cover;
      }
    }

    .photo__three {
      max-width: 50%;
      min-width: 50%;
      padding-top: 5px;
      padding-left: 2px;
      overflow: hidden;
      img {
        min-width: 100%;
        max-height: 100vh;
        min-height: 100%;
        object-fit: cover;
      }
    }

    img {
      min-width: 100%;
    }
  }
  p {
    font-weight: $font-weight-bold;
  }
}

.project_groundPlan {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: 50px;

  .project-groundPlan__title {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    margin: 100px 0px 50px 0px;
    p {
      font-family: $font-style-n1;
      font-weight: $font-weight-normal;
      font-size: $font-size-n9;
    }
    hr {
      width: 50%;
      margin-bottom: 20px;
    }
  }

  .project-groundPlan__title--green {
    color: $primary;
  }

  .project-groundPlan__sub-title {
    width: 30%;
    margin: 0px 0px 50px 0px;
    p {
      padding: 0px;
      margin: 0px;
      font-family: $font-style-n0;
      font-weight: $font-weight-normal;
      font-size: $font-size-n4;
    }
  }

  .project-groundPlan__sub-title-two {
    p {
      font-family: $font-style-n0;
      font-weight: $font-weight-extra-light;
      font-size: $font-size-n6;
      margin: 20px;
    }
  }

  .project-groundPlan__content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    .photo__one {
      min-width: 70%;
    }
    img {
      min-width: 100%;
    }
  }

  p {
    font-weight: $font-weight-bold;
  }
}

.project-groundPlan__banner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 50px;

  .project-groundPlan-banner__title {
    width: 25%;
    margin-right: 30px;
    min-height: 120px;
    font-family: $font-style-n0;
    font-weight: $font-weight-normal;
    font-size: $font-size-n8;
    background-color: $primary;
    text-align: center;
    line-height: 50px;
    padding: 5px;
  }

  .project-groundPlan-banner__sub-title {
    width: 30%;
    display: flex;
    flex-flow: column nowrap;
    max-height: 120px;
    min-height: 120px;

    .chakra-icon {
      font-size: 30px;
      margin-bottom: 20px;
    }

    p {
      font-family: $font-style-n0;
      font-weight: $font-weight-normal;
      font-size: $font-size-n1;
      text-align: left;
    }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .project {
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
    padding-top: 10px;
    .project__title {
      hr {
        width: 60%;
        margin-bottom: 10px;
      }
      p {
        width: 100%;
        text-align: center;
        font-size: $font-size-n5;
        font-family: $font-style-n1;
        font-weight: $font-weight-normal;
        padding: 0px;
        line-height: 25px;
      }
      margin-bottom: 30px;
    }

    .project__body {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0px 5%;
      .project-body__img {
        max-width: 80%;
      }

      .project-body__box {
        width: 80%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        .project-box__comment-one {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: flex-start;

          .project-box__line {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: center;
            display: none;
            img {
              max-width: 50px;
            }
            hr {
              height: 240px;
              width: 0.5px;
              border-width: 0;
            }
          }

          .project-box__line-two {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            width: 100%;
            p {
              text-align: center;
            }

            .comment_one {
              font-size: $font-size-n3;
              line-height: 25px;
              padding: 20px 0px 20px 0px;
              font-family: $font-style-n1;
              font-weight: $font-weight-normal;
              color: $primary;
            }

            .comment_two {
              width: 100%;
              background-color: $primary;
              display: flex;
              flex-flow: column nowrap;
              justify-content: center;
              align-items: center;
              align-self: center;
              margin: 0px;
              padding: 10px 0px;
              p {
                font-size: $font-size-n3;
                font-family: $font-style-n0;
                font-weight: $font-weight-normal;
                color: $white;
                text-align: center;
                line-height: 25px;
                margin: 0px !important;
                padding: 0px !important;
              }
            }
          }
        }

        .comment_three {
          font-weight: $font-weight-normal;
          margin-top: 20px;
          color: $white;
          font-size: $font-size-n1;
          text-align: center !important;
        }
      }
    }
  }

  .project_photos {
    width: 100%;
    padding-top: 10px;
    overflow: hidden;

    .project-photos__title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;
      padding-bottom: 40px;
      p {
        font-size: $font-size-n5;
      }
      hr {
        width: 60%;
        margin-bottom: 20px;
      }
    }
  }

  .project_groundPlan {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding-top: 10px;

    .project-groundPlan__title {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;
      margin: 100px 0px 20px 0px;
      p {
        font-family: $font-style-n1;
        font-weight: $font-weight-normal;
        font-size: $font-size-n5;
      }
      hr {
        width: 70%;
        margin-bottom: 20px;
      }
    }

    .project-groundPlan__sub-title {
      width: 90%;
      margin: 0px 0px 30px 0px;
      p {
        font-size: $font-size-n1;
      }
    }

    .project-groundPlan__content {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      .photo__one {
        min-width: 70%;
      }
      img {
        min-width: 100%;
      }
    }
  }

  .project-groundPlan__banner {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 50px;

    .project-groundPlan-banner__title {
      width: 80%;
      //max-height: 80px;
      min-height: 80px;
      font-size: $font-size-n3;
      padding: 20px 5px;
      margin: 0px 20px 10px;
    }

    .project-groundPlan-banner__sub-title {
      width: 80%;
      display: flex;
      flex-flow: column nowrap;
      max-height: 120px;
      min-height: 120px;
      margin-top: 20px;

      .chakra-icon {
        font-size: 30px;
        margin-bottom: 20px;
      }

      p {
        font-size: $font-size-n1;
        text-align: left;
      }
    }
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .project {
    .project__title {
      hr {
        width: 40%;
        margin-bottom: 30px;
      }
      p {
        width: 80%;
        font-size: $font-size-n5;
        padding: 2px;
        line-height: 35px;
      }
    }

    .project__body {
      padding: 10% 5%;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      .project-body__img {
        min-width: 300px;
        max-width: 300px;
        padding: 0px;
        //padding: 5%;
      }

      .project-body__box {
        min-width: 300px;
        max-width: 300px;
        min-height: 350px;
        max-height: 350px;
        margin-left: 30px;
        overflow: hidden;
        .project-box__comment-one {
          min-height: 200px;
          max-height: 200px;
          .project-box__line {
            width: 60px;
            height: 200px;
            //padding: 0px 50px 0px 0px;
            img {
              max-width: 50px;
              margin: 0px;
              padding: 0px;
              //margin-top: 10px;
              //margin-bottom: 20px;
            }
            hr {
              height: 140px;
              width: 0.5px;
              border-width: 0;
            }
          }

          .project-box__line-two {
            min-height: 180px;
            width: 90%;
            .comment_one {
              font-size: $font-size-n4;
              line-height: 25px;
              font-family: $font-style-n1;
              font-weight: $font-weight-normal;
              color: $primary;
            }

            .comment_two {
              p {
                font-size: $font-size-n2;
                line-height: 25px;
              }
            }
          }
        }

        .comment_three {
          font-size: $font-size-n1;
          font-family: $font-style-n0;
          font-weight: $font-weight-normal;
          margin-top: 40px;
          margin-left: 25px;
          color: $white;
          text-align: left !important;
        }
      }
    }
  }

  .project_photos {
    width: 100%;
    padding-top: 10px;
    overflow: hidden;

    .project-photos__title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;
      padding-bottom: 40px;
      p {
        font-size: $font-size-n5;
      }
      hr {
        width: 60%;
        margin-bottom: 20px;
      }
    }
  }

  .project_groundPlan {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding-top: 10px;

    .project-groundPlan__title {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;
      margin: 100px 0px 20px 0px;
      p {
        font-family: $font-style-n1;
        font-weight: $font-weight-normal;
        font-size: $font-size-n5;
      }
      hr {
        width: 70%;
        margin-bottom: 20px;
      }
    }

    .project-groundPlan__sub-title {
      width: 90%;
      margin: 0px 0px 30px 0px;
      p {
        font-size: $font-size-n1;
      }
    }

    .project-groundPlan__content {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      .photo__one {
        min-width: 70%;
      }
      img {
        min-width: 100%;
      }
    }
  }

  .project-groundPlan__banner {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 50px;

    .project-groundPlan-banner__title {
      width: 80%;
      //max-height: 80px;
      min-height: 80px;
      font-size: $font-size-n3;
      padding: 20px 5px;
      margin: 0px 20px 10px;
    }

    .project-groundPlan-banner__sub-title {
      width: 80%;
      display: flex;
      flex-flow: column nowrap;
      max-height: 120px;
      min-height: 120px;
      margin-top: 20px;

      .chakra-icon {
        font-size: 30px;
        margin-bottom: 20px;
      }

      p {
        font-size: $font-size-n1;
        text-align: left;
      }
    }
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .project {
    .project__title {
      min-height: auto;
      hr {
        width: 40%;
        margin-bottom: 30px;
      }
      p {
        width: 80%;
        font-size: $font-size-n6;
        padding: 2px;
        line-height: 35px;
      }
    }

    .project__body {
      padding: 10% 5%;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      .project-body__img {
        min-width: 400px;
        max-width: 400px;
        padding: 0px;
        //padding: 5%;
      }

      .project-body__box {
        min-width: 300px;
        max-width: 300px;
        min-height: 350px;
        max-height: 350px;
        margin-left: 30px;
        overflow: hidden;
        .project-box__comment-one {
          min-height: 200px;
          max-height: 200px;
          .project-box__line {
            width: 60px;
            height: 200px;
            //padding: 0px 50px 0px 0px;
            img {
              max-width: 50px;
              margin: 0px;
              padding: 0px;
              //margin-top: 10px;
              //margin-bottom: 20px;
            }
            hr {
              height: 140px;
              width: 0.5px;
              border-width: 0;
            }
          }

          .project-box__line-two {
            min-height: 180px;
            width: 90%;
            .comment_one {
              font-size: $font-size-n4;
              line-height: 25px;
              font-family: $font-style-n1;
              font-weight: $font-weight-normal;
              color: $primary;
            }

            .comment_two {
              p {
                font-size: $font-size-n2;
                line-height: 25px;
              }
            }
          }
        }

        .comment_three {
          font-size: $font-size-n1;
          font-family: $font-style-n0;
          font-weight: $font-weight-normal;
          margin-top: 40px;
          margin-left: 25px;
          color: $white;
          text-align: left !important;
        }
      }
    }
  }

  .project_photos {
    .project-photos__title {
      padding-bottom: 50px;
      p {
        font-family: $font-style-n1;
        font-weight: $font-weight-normal;
        font-size: $font-size-n5;
        line-height: 30px;
      }
      hr {
        width: 30%;
        margin-bottom: 20px;
      }
    }
  }

  .project_groundPlan {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding-top: 50px;

    .project-groundPlan__title {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;
      margin: 100px 0px 50px 0px;
      p {
        font-family: $font-style-n1;
        font-weight: $font-weight-normal;
        font-size: $font-size-n7;
      }
      hr {
        width: 50%;
        margin-bottom: 20px;
      }
    }

    .project-groundPlan__sub-title {
      width: 80%;
      margin: 0px 0px 50px 0px;
      p {
        padding: 0px;
        margin: 0px;
        font-family: $font-style-n0;
        font-weight: $font-weight-normal;
        font-size: $font-size-n3;
      }
    }

    .project-groundPlan__sub-title-two {
      p {
        font-family: $font-style-n0;
        font-weight: $font-weight-extra-light;
        font-size: $font-size-n6;
        margin: 20px;
      }
    }

    .project-groundPlan__content {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      .photo__one {
        min-width: 70%;
      }
      img {
        min-width: 100%;
      }
    }
  }

  .project-groundPlan__banner {
    display: flex;
    flex-flow: row nowrap !important;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin: 50px;

    .project-groundPlan-banner__title {
      width: 200px;
      min-height: 160px;
      max-height: 160px;
      
      margin-right: 30px;
      font-family: $font-style-n0;
      font-weight: $font-weight-normal;
      font-size: $font-size-n8;
      background-color: $primary;
      text-align: center;
      line-height: 50px;
      padding: 5px;
    }

    .project-groundPlan-banner__sub-title {
      width: 200px;
      display: flex;
      flex-flow: column nowrap;
      max-height: 160px;
      min-height: 160px;
      margin-top: 0px;

      .chakra-icon {
        font-size: 30px;
        margin-bottom: 20px;
      }

      p {
        font-family: $font-style-n0;
        font-weight: $font-weight-normal;
        font-size: $font-size-n1;
        text-align: left;
      }
    }
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .project {
    .project__title {
      hr {
        width: 45%;
      }
    }

    .project__body {
      .project-body__img {
        min-width: 500px;
        max-width: 500px;
        padding: 0px;
        //padding: 5%;
      }
    }
  }

  .project_photos {
    width: 100%;
    padding-top: 50px;
    overflow: hidden;

    .project-photos__title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;
      padding-bottom: 50px;
      p {
        font-family: $font-style-n1;
        font-weight: $font-weight-normal;
        font-size: $font-size-n7;
        line-height: 45px;
      }
      hr {
        width: 30%;
        margin-bottom: 30px;
      }
    }
    .project-photos__title--green {
      color: $primary;
    }

    .project-photos__content {
      display: flex;
      flex-flow: row wrap;
      max-height: 200vh;
      overflow: hidden;

      .photo__one {
        img {
          min-width: 100%;
          max-height: 100vh;
          object-fit: cover;
        }
      }

      .photo__two {
        max-width: 50%;
        min-width: 50%;
        padding-top: 5px;
        padding-right: 2px;
        overflow: hidden;
        img {
          min-width: 100%;
          max-height: 100vh;
          min-height: 100%;
          object-fit: cover;
        }
      }

      .photo__three {
        max-width: 50%;
        min-width: 50%;
        padding-top: 5px;
        padding-left: 2px;
        overflow: hidden;
        img {
          min-width: 100%;
          max-height: 100vh;
          min-height: 100%;
          object-fit: cover;
        }
      }

      img {
        min-width: 100%;
      }
    }
    p {
      font-weight: $font-weight-bold;
    }
  }

  .project_groundPlan {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding-top: 50px;

    .project-groundPlan__title {
      width: 35%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;
      margin: 100px 0px 50px 0px;
      p {
        font-family: $font-style-n1;
        font-weight: $font-weight-normal;
        font-size: $font-size-n9;
      }
      hr {
        width: 50%;
        margin-bottom: 20px;
      }
    }

    .project-groundPlan__title--green {
      color: $primary;
    }

    .project-groundPlan__sub-title {
      width: 30%;
      margin: 0px 0px 50px 0px;
      p {
        padding: 0px;
        margin: 0px;
        font-family: $font-style-n0;
        font-weight: $font-weight-normal;
        font-size: $font-size-n4;
      }
    }

    .project-groundPlan__sub-title-two {
      p {
        font-family: $font-style-n0;
        font-weight: $font-weight-extra-light;
        font-size: $font-size-n6;
        margin: 20px;
      }
    }

    .project-groundPlan__content {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      .photo__one {
        min-width: 70%;
      }
      img {
        min-width: 100%;
      }
    }

    p {
      font-weight: $font-weight-bold;
    }
  }

  .project-groundPlan__banner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    margin: 50px;

    .project-groundPlan-banner__title {
      width: 200px;
      margin-right: 30px;
      max-height: 160px;
      min-height: 160px;
      font-family: $font-style-n0;
      font-weight: $font-weight-normal;
      font-size: $font-size-n8;
      background-color: $primary;
      text-align: center;
      line-height: 50px;
      padding: 5px;
    }

    .project-groundPlan-banner__sub-title {
      width: 200px;
      display: flex;;
      flex-flow: column nowrap;
      margin-top: 0px;
      max-height: 160px;
      min-height: 160px;

      .chakra-icon {
        font-size: 30px;
        margin-bottom: 20px;
      }

      p {
        font-family: $font-style-n0;
        font-weight: $font-weight-normal;
        font-size: $font-size-n1;
        text-align: left;
      }
    }
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .project {
    .project__title {
      hr {
        width: 30%;
      }
    }

    .project__body {
      .project-body__img {
        min-width: 600px;
        max-width: 600px;
        //padding: 5%;
      }

      .project-body__box {
        min-width: 400px;
        max-width: 400px;
        min-height: 450px;
        max-height: 450px;
        margin-left: 30px;
        overflow: hidden;
        .project-box__comment-one {
          min-height: 250px;
          max-height: 250px;
          .project-box__line {
            width: 60px;
            height: 300px;
            //padding: 0px 50px 0px 0px;
            img {
              max-width: 50px;
              margin: 0px;
              padding: 0px;
              //margin-top: 10px;
              //margin-bottom: 20px;
            }
            hr {
              height: 200px;
              width: 0.5px;
              border-width: 0;
            }
          }

          .project-box__line-two {
            min-height: 245px;
            width: 90%;
            .comment_one {
              font-size: $font-size-n7;
              line-height: 37px;
            }

            .comment_two {
              p {
                font-size: $font-size-n5;
                line-height: 25px;
              }
            }
          }
        }

        .comment_three {
          font-size: $font-size-n1;
          font-family: $font-style-n0;
          font-weight: $font-weight-normal;
          margin-top: 40px;
          margin-left: 30px;
          color: $white;
          text-align: left !important;
        }
      }
    }
  }
}
