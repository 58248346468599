@import '../../CommonUI/styles.scss';

.spinnerFull {
  min-width: 100%;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  //background-image: url('./../img/backgrounds/bg-white-min.png');
  background-size: cover;
  img {
    width: 15%;
  }
  p {
    font-weight: $font-weight-bold;
    font-size: $font-size-n5;
    color: $fourth;
  }
}

.spinner__spin {
  font-size: 100px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .spinnerFull {
    img {
      width: 30%;
    }
    p {
      font-size: $font-size-n3;
    }
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .spinnerFull {
    img {
      width: 23%;
    }
    p {
      font-size: $font-size-n3;
    }
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .spinnerFull {
    img {
      width: 23%;
    }
    p {
      font-size: $font-size-n3;
    }
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .spinnerFull {
    img {
      width: 18%;
    }
    p {
      font-size: $font-size-n4;
    }
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .spinnerFull {
    img {
      width: 15%;
    }
    p {
      font-size: $font-size-n5;
    }
  }
}
