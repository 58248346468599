@import url('https://fonts.googleapis.com/css2?family=Lora&family=Raleway:ital,wght@0,100;0,200;0,400;0,500;0,600:1,100&display=swap');
$black: #000;
$white: rgb(255, 255, 255);
$primary: rgb(148, 189, 99);
$second: rgba(41, 106, 37, 0.61);
$thrid: rgb(41, 106, 37);
$fourth: rgb(11, 19, 10);
$fifth: #eeefe9;
$sixth: #474747;

//FONT STYLE
$font-style-n0: 'Raleway', sans-serif;
$font-style-n2: 'Raleway ital', sans-serif;
$font-style-n1: 'Lora', serif;
//FONT SIZE
$font-size-n0: 12px;
$font-size-n1: 16px;
$font-size-n2: 18px;
$font-size-n3: 20px;
$font-size-n4: 23px;
$font-size-n5: 25px;
$font-size-n6: 30px;
$font-size-n7: 35px;
$font-size-n8: 40px;
$font-size-n9: 45px;
$font-size-n10: 50px;
$font-size-n11: 55px;
$font-size-n12: 60px;
$font-size-n13: 70px;

//FONT WEIGHT
$font-weight-extra-light: 200;
$font-weight-thin: 100;
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 600;

$font-line-height: 65px;
$font-line-height-n0: 20px;
$font-line-height-n1: 25px;
$font-line-height-n2: 35px;
$font-line-height-n3: 45px;
$font-line-height-n4: 50px;

ul {
  list-style: none;
}

hr {
  //width: 80%;
  height: 0.1px;
  background-color: white;
}

p {
  color: $white;
}

.circulo {
  width: 100px;
  height: 100px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #5cb85c;
}

a,
li,
label {
  color: $white;
}

span {
  color: $primary;
}

body {
  max-width: 3000px;
}

* {
  scroll-behavior: smooth;
  color: $white !important;
}
