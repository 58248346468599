@import '../../../CommonUI/styles.scss';

.contact {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 50px 20%;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  .contact__title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    width: 80%;
    margin-bottom: 80px;
    hr {
      width: 35%;
      margin-bottom: 30px;
    }

    p {
      font-family: $font-style-n1;
      font-weight: $font-weight-normal;
      font-size: $font-size-n9;
    }
  }

  .contact__subtitle {
    width: 100%;
    p {
      font-family: $font-style-n1;
      font-weight: $font-weight-normal;
      font-size: $font-size-n4;
    }
  }

  .contact__title-green {
    color: $primary;
  }
}


.chakra-modal__content-container{
  .chakra-modal__content{
    background: $fourth;
    .chakra-button{
      background-color: $primary;
    }
  
  }
}

.contact__slider {
  width: 100%;
  padding: 0% 10% 0% 10%;
  display: flex;
  //background-color: aqua;
  flex-flow: row nowrap;
  justify-content: space-between;
  .contact-slider__one {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 20%;
    img {
      max-width: 50px;
    }

    .title {
      font-size: $font-size-n1;
    }

    .comment {
      font-size: $font-size-n1;
    }
  }

  .contact-slider__two {
    display: flex;
    flex-flow: column nowrap;
    width: 20%;
    align-items: center;
    margin-top: 100px;
    img {
      max-width: 50px;
    }

    .comment {
      font-size: $font-size-n1;
    }
  }

  .contact-slider__three {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 20%;
    img {
      max-width: 50px;
    }

    .comment {
      font-size: $font-size-n1;
    }
  }
}

.contact__form {
  width: 100%;
  margin: 50px;
  .chakra-form-control {
    background-color: $sixth;
    padding: 20px;
    input,
    textarea {
      background-color: transparent;
      border-bottom: white 1px solid;
      border-radius: 0px;
      &:focus-visible {
        border-color: transparent;
        border-bottom: $primary 1px solid;
      }

      &[aria-invalid='true'],
      &[data-invalid] {
        border-color: transparent;
        border-bottom: #fc8181 1px solid;
      }
    }
  }
  .contact-from__wrap {
    min-width: 100%;
    display: flex;
    flex-flow: row nowrap;

    .chakra-form-control--right {
      margin-left: 5px;
    }

    .chakra-form-control--left {
      margin-right: 10px;
    }
  }
  .contact-form__button {
    width: 33%;
    margin-left: 33%;
    margin-top: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    .chakra-button {
      background-color: $primary;
      border-radius: 0px;
    }
  }

  .contact-form__check {
    margin-top: 30px;
    span {
      color: $primary !important;
    }
    .chakra-checkbox__control {
      //background-color: $primary !important;
      color: $white !important;
      background: $primary !important;
      border-color: transparent !important;
    }
    .chakra-checkbox__label {
    }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .contact {
    width: 100%;
    max-width: 100%;
    padding: 50px 5%;
    min-height: 100vh;

    .contact__title {
      width: 100%;
      margin-bottom: 80px;
      hr {
        width: 60%;
        margin-bottom: 10px;
      }

      p {
        font-family: $font-style-n1;
        font-size: $font-size-n5;
      }
    }

    .contact__subtitle {
      p {
        font-size: $font-size-n2;
      }
    }
  }

  .contact__slider {
    width: 100%;
    padding: 0% 10% 0% 10%;
    display: flex;
    //background-color: aqua;
    flex-flow: row nowrap;
    justify-content: space-between;
    .contact-slider__one {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      width: 20%;
      img {
        max-width: 50px;
      }

      .title {
        font-size: $font-size-n1;
      }

      .comment {
        font-size: $font-size-n1;
      }
    }

    .contact-slider__two {
      display: flex;
      flex-flow: column nowrap;
      width: 20%;
      align-items: center;
      margin-top: 100px;
      img {
        max-width: 50px;
      }

      .comment {
        font-size: $font-size-n1;
      }
    }

    .contact-slider__three {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      width: 20%;
      img {
        max-width: 50px;
      }

      .comment {
        font-size: $font-size-n1;
      }
    }
  }

  .contact__form {
    width: 100%;
    margin: 50px;
    .chakra-form-control {
      background-color: $sixth;
      padding: 20px;
      input,
      textarea {
        background-color: transparent;
        border-bottom: white 1px solid;
        border-radius: 0px;
        &:focus-visible {
          border-color: transparent;
          border-bottom: $primary 1px solid;
        }
      }
    }
    .contact-from__wrap {
      min-width: 100%;
      display: flex;
      flex-flow: row nowrap;

      .chakra-form-control--right {
        margin-left: 5px;
      }

      .chakra-form-control--left {
        margin-right: 10px;
      }
    }
    .contact-form__button {
      width: 33%;
      margin-left: 33%;
      margin-top: 50px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      .chakra-button {
        background-color: $primary;
        border-radius: 0px;
      }
    }

    .contact-form__check {
      margin-top: 30px;
      .chakra-checkbox__control {
        //background-color: $primary !important;
        color: $white !important;
        background: $primary !important;
        border-color: transparent !important;
      }
      .chakra-checkbox__label {
      }
    }
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .contact {
    width: 100%;
    padding: 50px 20%;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    .contact__title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;
      width: 80%;
      margin-bottom: 80px;
      hr {
        width: 35%;
        margin-bottom: 30px;
      }

      p {
        font-family: $font-style-n1;
        font-weight: $font-weight-normal;
        font-size: $font-size-n9;
      }
    }

    .contact__subtitle {
      width: 100%;
      p {
        font-family: $font-style-n1;
        font-weight: $font-weight-normal;
        font-size: $font-size-n4;
      }
    }

    .contact__title-green {
      color: $primary;
    }
  }

  .contact__slider {
    width: 100%;
    padding: 0% 10% 0% 10%;
    display: flex;
    //background-color: aqua;
    flex-flow: row nowrap;
    justify-content: space-between;
    .contact-slider__one {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      width: 20%;
      img {
        max-width: 50px;
      }

      .title {
        font-size: $font-size-n1;
      }

      .comment {
        font-size: $font-size-n1;
      }
    }

    .contact-slider__two {
      display: flex;
      flex-flow: column nowrap;
      width: 20%;
      align-items: center;
      margin-top: 100px;
      img {
        max-width: 50px;
      }

      .comment {
        font-size: $font-size-n1;
      }
    }

    .contact-slider__three {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      width: 20%;
      img {
        max-width: 50px;
      }

      .comment {
        font-size: $font-size-n1;
      }
    }
  }

  .contact__form {
    width: 100%;
    margin: 50px;
    .chakra-form-control {
      background-color: $sixth;
      padding: 20px;
      input,
      textarea {
        background-color: transparent;
        border-bottom: white 1px solid;
        border-radius: 0px;
        &:focus-visible {
          border-color: transparent;
          border-bottom: $primary 1px solid;
        }
      }
    }
    .contact-from__wrap {
      min-width: 100%;
      display: flex;
      flex-flow: row nowrap;

      .chakra-form-control--right {
        margin-left: 5px;
      }

      .chakra-form-control--left {
        margin-right: 10px;
      }
    }
    .contact-form__button {
      width: 33%;
      margin-left: 33%;
      margin-top: 50px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      .chakra-button {
        background-color: $primary;
        border-radius: 0px;
      }
    }

    .contact-form__check {
      margin-top: 30px;
      .chakra-checkbox__control {
        //background-color: $primary !important;
        color: $white !important;
        background: $primary !important;
        border-color: transparent !important;
      }
      .chakra-checkbox__label {
      }
    }
  }
}
