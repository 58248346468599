@import '../../../CommonUI/styles.scss';

.banner {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  //background:linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(5, 5, 5, 0.3)), url('../../../CommonUI/Img/banner-min.jpg');
  background-image: url('../../../CommonUI/Img/banner-min.png');
  background-size: cover;
  background-repeat: no-repeat;
  button {
    background-color: $primary;
    color: $white;
  }
}

.banner__comments {
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 25vh;
  padding-left: 160px;
  align-items: flex-start;
  p {
    text-align: left;
    margin-bottom: 20px;
  }
  .comment_one {
    font-family: $font-style-n0;
    font-weight: $font-weight-normal;
    line-height:$font-line-height;
    font-size: $font-size-n10;
  }
  .comment_two {
    font-family: $font-style-n0;
    font-weight: $font-weight-extra-light;
    font-size: $font-size-n5;
  }
  button {
    margin-top: 20px;
    border-radius: 0px;
    font-family: $font-style-n0;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .banner__comments {
    width: 90%;
    padding-top: 20vh;
    padding-left: 30px;
    align-items: flex-start;
    p {
      text-align: left;
      line-height: 25px;
      margin-bottom: 20px;
    }
    .comment_one {
      //color: $primary;
      line-height: 25px;
      font-family: $font-style-n0;
      font-weight: $font-weight-normal;
      font-size: $font-size-n3;
    }

  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .banner__comments {
    width: 80%;
    padding-top: 20vh;
    padding-left: 30px;
    align-items: flex-start;
    p {
      text-align: left;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .comment_one {
      //color: $primary;
      line-height: 30px;
      font-family: $font-style-n0;
      font-weight: $font-weight-normal;
      font-size: $font-size-n6;
    }

    .commnet_two {
      font-family: $font-style-n0;
      font-weight: $font-weight-extra-light;
      font-size: $font-size-n5;
    }

  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .banner__comments {
    width: 80%;
    padding-left: 80px;
    padding-top: 20vh;
    .comment_one {
      line-height: $font-line-height-n3;
      font-size: $font-size-n7;
    }
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {  
  .banner__comments {
    width: 70%;
    .comment_one {
      line-height: $font-line-height-n3;
      font-size: $font-size-n7;
    }
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {  
  .banner__comments {
    width: 50%;
    padding-top: 15vh;
    padding-left: 160px;
    p {
      text-align: left;
      margin-bottom: 20px;
    }
    .comment_one {
      line-height:$font-line-height;
      font-size: $font-size-n9;
    }
    .commnet_two {
      font-family: $font-style-n0;
      font-weight: $font-weight-extra-light;
      font-size: $font-size-n5;
    }
    button {
      margin-top: 20px;
      border-radius: 0px;
      font-family: $font-style-n0;
    }
  }
}
