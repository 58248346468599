@import '../../../CommonUI/styles.scss';

.valley {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 0% 10%;
  min-height: 100vh;
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  background-image: url('./img/valle_huancabamba-min.png');
  background-size: cover;
  background-repeat: no-repeat;
  .logo {
    width: 17%;
    margin: 20vh 50px 0px 50px;
  }
  .title {
    //color: $primary;
    font-family: $font-style-n1;
    font-weight: $font-weight-normal;
    text-align: left;
    line-height: 45px;
    min-width: 30%;
    margin-top: 50vh;
    font-size: $font-size-n7;
    hr {
      margin-bottom: 50px;
    }
  }
}

.valley__slider {
  min-width: 50%;
  display: flex;
  //background-color: aqua;
  flex-flow: column nowrap;
  margin-top: 50vh;
  margin-left: 20px;
  justify-content: space-between;
  img {
    max-width: 50px;
  }

  .title {
    font-size: $font-size-n1;
  }

  .comment {
    font-family: $font-style-n0;
    font-weight: $font-weight-normal;
    font-size: $font-size-n2;
  }

  hr {
    margin-bottom: 50px;
    width: 50%;
  }

  article {
    //margin-top: 30px;
    text-align: left;
  }
}

.valley__slider--hide {
  display: none;
}

.valley-slider__buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 5px;
  width: 100%;
  hr {
    width: 20px;
    height: 20px;
    margin: 10px;
    background-color: $second;
  }
}

.valley-slider__buttons--focus {
  background-color: $primary !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .valley {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    .logo {
      width: 30%;
      margin: 40px 40px 0px 40px;
    }
    .title {
      //color: $primary;
      font-family: $font-style-n1;
      font-weight: $font-weight-normal;
      text-align: left;
      line-height: 20px;
      min-width: 30%;
      margin-top: 50px;
      font-size: $font-size-n2;
      hr {
        margin-bottom: 10px;
      }
    }
  }

  .valley__slider {
    min-width: 50%;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 80px;
    margin-left: 20px;
    justify-content: space-between;

    .title {
      font-size: $font-size-n1;
    }

    .comment {
      font-family: $font-style-n0;
      font-weight: $font-weight-normal;
      font-size: $font-size-n1;
    }

    .valley-slider__buttons {
      margin-top: 20px;
    }

    hr {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .valley-slider__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 5px;
    width: 100%;
    hr {
      width: 20px;
      height: 20px;
      margin: 10px;
      background-color: $second;
    }
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .valley {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    .logo {
      width: 200px;
      margin: 40px 40px 0px 40px;
    }
    .title {
      //color: $primary;
      font-family: $font-style-n1;
      font-weight: $font-weight-normal;
      text-align: left;
      line-height: 30px;
      min-width: 30%;
      margin-top: 50px;
      font-size: $font-size-n4;
      hr {
        margin-bottom: 10px;
      }
    }
  }

  .valley__slider {
    min-width: 50%;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 80px;
    margin-left: 20px;
    justify-content: space-between;

    .title {
      font-size: $font-size-n1;
    }

    .comment {
      font-family: $font-style-n0;
      font-weight: $font-weight-normal;
      font-size: $font-size-n3;
    }

    .valley-slider__buttons {
      margin-top: 20px;
    }

    hr {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .valley-slider__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 5px;
    width: 100%;
    hr {
      width: 20px;
      height: 20px;
      margin: 10px;
      background-color: $second;
    }
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .valley {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    padding: 0% 5%;
    min-height: 100vh;
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    background-image: url('./img/valle_huancabamba-min.png');
    background-size: cover;
    background-repeat: no-repeat;
    .logo {
      max-width: 200px;
      margin: 20vh 50px 0px 50px;
    }
    .title {
      //color: $primary;
      font-family: $font-style-n1;
      font-weight: $font-weight-normal;
      text-align: left;
      line-height: 40px;
      min-width: 26%;
      margin-top: 50vh;
      font-size: $font-size-n6;
      hr {
        margin-bottom: 50px;
      }
    }
  }

  .valley__slider {
    min-width: 40%;
    display: flex;
    //background-color: aqua;
    flex-flow: column nowrap;
    margin-top: 50vh;
    margin-left: 10px;
    justify-content: space-between;
    img {
      max-width: 50px;
    }

    .title {
      font-size: $font-size-n1;
    }

    .comment {
      font-family: $font-style-n0;
      font-weight: $font-weight-normal;
      font-size: $font-size-n3;
    }

    hr {
      margin-bottom: 50px;
      width: 60%;
    }
  }

  .valley-slider__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 5px;
    width: 100%;
    hr {
      width: 20px;
      height: 20px;
      margin: 10px;
      background-color: $second;
    }
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .valley {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    padding: 0% 5%;
    min-height: 100vh;
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    background-image: url('./img/valle_huancabamba-min.png');
    background-size: cover;
    background-repeat: no-repeat;
    .logo {
      max-width: 200px;
      margin: 20vh 50px 0px 50px;
    }
    .title {
      //color: $primary;
      font-family: $font-style-n1;
      font-weight: $font-weight-normal;
      text-align: left;
      line-height: 45px;
      min-width: 30%;
      margin-top: 50vh;
      font-size: $font-size-n7;
      hr {
        margin-bottom: 50px;
      }
    }
  }

  .valley__slider {
    min-width: 40%;
    display: flex;
    //background-color: aqua;
    flex-flow: column nowrap;
    margin-top: 50vh;
    margin-left: 10px;
    justify-content: space-between;
    img {
      max-width: 50px;
    }

    .comment {
      font-family: $font-style-n0;
      font-weight: $font-weight-normal;
      font-size: $font-size-n4;
    }

    hr {
      margin-bottom: 50px;
      width: 60%;
    }
  }

  .valley-slider__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 5px;
    width: 100%;
    hr {
      width: 20px;
      height: 20px;
      margin: 10px;
      background-color: $second;
    }
  }
}
