@import '../../../CommonUI/styles.scss';

.history {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

.history__title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  .title {
    max-width: 350px;
  }
  hr {
    width: 65%;
  }
}

.history__slider {
  width: 100%;
  padding: 0% 0% 0% 10%;
  display: flex;
  min-height: 90vh;
  //background-color: aqua;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  .history-slider__portail {
    display: flex;
    flex-flow: column nowrap;
    //align-items: center;
    justify-content: flex-start;
    align-items: flex-start;
    //justify-content: space-between;
    margin-top: 80px;
    min-height: 100vh;
    width: 50%;

    .history-slider-portail__img {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      max-width: 100%;
      p {
        padding: 20px;
        font-size: $font-size-n8;
        text-align: right;
      }
      img {
        margin: 15px 20px;
        max-width: 450px;
        min-width: 450px;
      }
    }

    .history-slider-portail__img--focus {
      img {
        margin: 15px 20px;
        max-width: 520px;
        min-width: 520px;
      }
    }
  }

  .history-slider__comment {
    display: flex;
    min-height: 100vh;
    margin-top: 200px;
    flex-flow: column nowrap;
    justify-content: flex-start;
    width: 35%;

    .comment {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      text-align: start;
      align-items: center;
      font-family: $font-style-n0;
      font-weight: $font-weight-normal;
      font-size: $font-size-n3;

      .comment--title {
        color: $primary;
        padding-bottom: 50px;
        font-family: $font-style-n0;
        font-weight: $font-weight-bold;
        font-size: $font-size-n5;
      }
    }

    .comment--slogan {
      color: $primary;
      padding-top: 50px;
      font-family: $font-style-n0;
      font-weight: $font-weight-bold;
      font-size: $font-size-n5;
    }

    .comment--hide {
      display: none;
    }
  }
}

.history_number {
  font-weight: $font-weight-thin;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .history {
    min-height: 800px !important;
    max-height: 800px !important;
  }

  .history__title {
    padding-bottom: 150px;
    .title {
      max-width: 130px;
    }
  }

  .history__slider {
    width: 100%;
    padding: 0% 0% 0% 0%;
    display: flex;
    min-height: 90vh;
    //background-color: aqua;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    .history-slider__portail {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      margin-top: 80px;
      margin-bottom: 80px;
      min-height: 20px;
      width: 100%;
      .history-slider-portail__img {
        img {
          margin: 10px 10px;
          max-width: 85px;
          min-width: 85px;
          max-height: 85px;
          min-height: 85px;
          object-fit: cover;

          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          border-radius: 50%;
        }
      }

      .history-slider-portail__img--focus {
        img {
          margin: 10px 10px;
          max-width: 85px;
          min-width: 85px;
          max-height: 85px;
          min-height: 85px;

          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          border-radius: 50%;
        }
      }
    }

    .history-slider__comment {
      display: flex;
      min-height: 100vh;
      margin-top: 50px;
      flex-flow: column nowrap;
      align-items: center;
      width: 80%;

      .comment {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        text-align: start;
        align-items: center;
        font-family: $font-style-n0;
        font-weight: $font-weight-normal;
        font-size: $font-size-n1;

        .comment--title {
          color: $primary !important;
          padding-bottom: 50px;
          font-family: $font-style-n0;
          font-weight: $font-weight-bold;
          font-size: $font-size-n2;
        }
      }
      .comment--slogan {
        color: $primary !important;
        padding-top: 50px;
        font-family: $font-style-n2;
        font-weight: 100;
        font-style: italic;
        font-size: $font-size-n2;
      }
  
    }
  }

  .history_number {
    display: none;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .history {
    min-height: 800px !important;
    max-height: 800px !important;
  }

  .history__title {
    .title {
      max-width: 200px;
    }
  }

  .history_number {
    display: none;
  }

  .history__slider {
    width: 100%;
    padding: 0% 0% 0% 0%;
    display: flex;
    min-height: 90vh;
    //background-color: aqua;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    .history-slider__portail {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      margin-top: 80px;
      min-height: 20px;
      width: 100%;
      .history-slider-portail__img {
        img {
          margin: 10px 20px;
          max-width: 120px;
          min-width: 120px;
          max-height: 120px;
          min-height: 120px;
          object-fit: cover;

          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          border-radius: 50%;
        }
      }

      .history-slider-portail__img--focus {
        img {
          margin: 10px 20px;
          max-width: 120px;
          min-width: 120px;
          max-height: 120px;
          min-height: 120px;

          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          border-radius: 50%;
        }
      }
    }

    .history-slider__comment {
      display: flex;
      min-height: 100vh;
      margin-top: 50px;
      flex-flow: column nowrap;
      align-items: center;
      width: 80%;

      .comment {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        text-align: start;
        align-items: center;
        font-family: $font-style-n0;
        font-weight: $font-weight-normal;
        font-size: $font-size-n1;

        .comment--title {
          color: $primary !important;
          padding-bottom: 50px;
          font-family: $font-style-n0;
          font-weight: $font-weight-bold;
          font-size: $font-size-n2;
        }
      }

      .comment--slogan {
        color: $primary !important;
        padding-top: 50px;
        font-family: $font-style-n2;
        font-weight: 100;
        font-style: italic;
        font-size: $font-size-n2;
      }
    }
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .history__title {
    padding-bottom: 150px;
    .title {
      max-width: 250px;
    }
  }

  .history__slider {
    width: 100%;
    padding: 0% 0% 0% 0%;
    display: flex;
    min-height: 90vh;
    //background-color: aqua;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .history-slider__comment {
      display: flex;
      min-height: 100vh;
      margin-top: 200px;
      flex-flow: column nowrap;
      align-items: center;
      width: 80%;

      .comment {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        text-align: start;
        align-items: center;
        font-family: $font-style-n0;
        font-weight: $font-weight-normal;
        font-size: $font-size-n2;

        .comment--title {
          color: $primary !important;
          padding-bottom: 50px;
          font-family: $font-style-n0;
          font-weight: $font-weight-bold;
          font-size: $font-size-n3;
        }
      }
    }
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .history {
    min-height: 100vh !important;
    max-height: 100vh !important;
  }


  .history_number {
    display: block;
  }

  .history__title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    padding-bottom: 0px;
    .title {
      max-width: 300px;
    }
    hr {
      width: 65%;
    }
  }

  .history__slider {
    width: 100%;
    padding: 0% 0% 0% 6%;
    display: flex;
    min-height: 90vh;
    //background-color: aqua;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    .history-slider__portail {
      display: flex;
      flex-flow: column nowrap;
      //align-items: center;
      justify-content: flex-start;
      align-items: flex-start;
      //justify-content: space-between;
      margin-top: 80px;
      min-height: 100vh;
      width: 60%;
      overflow: hidden;

      .history-slider-portail__img {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        max-width: 100%;
        p {
          padding: 20px;
          font-size: $font-size-n8;
          text-align: right;
        }
        img {
          margin: 15px 20px;
          max-width: 380px;
          min-width: 380px;

          -moz-border-radius: 0%;
          -webkit-border-radius: 0%;
          border-radius: 0%;
        }
      }

      .history-slider-portail__img--focus {
        img {
          margin: 15px 20px;
          max-width: 440px;
          min-width: 440px;

          -moz-border-radius: 0%;
          -webkit-border-radius: 0%;
          border-radius: 0%;
        }
      }
    }

    .history-slider__comment {
      display: flex;
      min-height: 100vh;
      margin-top: 120px;
      flex-flow: column nowrap;
      justify-content: flex-start;
      width: 40%;
      margin-right: 20px;

      .comment {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        text-align: start;
        align-items: center;
        font-family: $font-style-n0;
        font-weight: $font-weight-normal;
        font-size: $font-size-n2;

        .comment--title {
          color: $primary;
          padding-bottom: 50px;
          font-family: $font-style-n0;
          font-weight: $font-weight-bold;
          font-size: $font-size-n3;
        }
      }

      .comment--hide {
        display: none;
      }
    }
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .history__title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    .title {
      max-width: 350px;
    }
    hr {
      width: 65%;
    }
  }

  .history__slider {
    width: 100%;
    padding: 0% 0% 0% 10%;
    display: flex;
    min-height: 90vh;
    //background-color: aqua;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    .history-slider__portail {
      display: flex;
      flex-flow: column nowrap;
      //align-items: center;
      justify-content: flex-start;
      align-items: flex-start;
      //justify-content: space-between;
      margin-top: 80px;
      min-height: 100vh;
      width: 60%;

      .history-slider-portail__img {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        max-width: 100%;
        p {
          padding: 20px;
          font-size: $font-size-n8;
          text-align: right;
        }
        img {
          margin: 15px 20px;
          max-width: 450px;
          min-width: 450px;
        }
      }

      .history-slider-portail__img--focus {
        img {
          margin: 15px 20px;
          max-width: 520px;
          min-width: 520px;
        }
      }
    }

    .history-slider__comment {
      display: flex;
      min-height: 100vh;
      margin-top: 130px;
      flex-flow: column nowrap;
      justify-content: flex-start;
      width: 40%;
      .comment {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        text-align: left;
        align-items: flex-start;
        font-family: $font-style-n0;
        font-weight: $font-weight-normal;
        font-size: $font-size-n3;

        .comment--title {
          color: $primary;
          padding-bottom: 50px;
          font-family: $font-style-n0;
          font-weight: $font-weight-bold;
          font-size: $font-size-n5;
        }
      }

      .comment--hide {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 1500px) {
  .history__slider {
    width: 100%;
    padding: 0% 0% 0% 10%;
    display: flex;
    min-height: 90vh;
    //background-color: aqua;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    .history-slider__comment {
      display: flex;
      min-height: 100vh;
      margin-top: 130px;
      flex-flow: column nowrap;
      justify-content: flex-start;
      width: 60%;
    }
  }
}

@media only screen and (min-width: 1750px) {
  .history__slider {
    width: 100%;
    padding: 0% 0% 0% 10%;
    display: flex;
    min-height: 90vh;
    //background-color: aqua;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    .history-slider__portail {
      display: flex;
      flex-flow: column nowrap;
      //align-items: center;
      justify-content: flex-start;
      align-items: flex-start;
      //justify-content: space-between;
      margin-top: 80px;
      min-height: 100vh;
      width: 50%;

      .history-slider-portail__img {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        max-width: 100%;
        p {
          padding: 20px;
          font-size: $font-size-n8;
          text-align: right;
        }
        img {
          margin: 15px 20px;
          max-width: 550px;
          min-width: 550px;
          max-height: 150px;
          min-height: 150px;
        }
      }

      .history-slider-portail__img--focus {
        img {
          margin: 15px 20px;
          max-width: 620px;
          min-width: 620px;

          max-height: 150px;
          min-height: 150px;
        }
      }
    }

    .history-slider__comment {
      display: flex;
      min-height: 100vh;
      margin-top: 160px;
      flex-flow: column nowrap;
      justify-content: flex-start;
      width: 45%;
    }
  }
}
