@import '../../CommonUI/styles.scss';

.navbar {
  width: 100%;
  padding: 10px;
}

.nav__wrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  //background-color: rgba(0, 0, 0, 0.216);
  min-height: 65px;
  border-radius: 99px;
  padding: 15px 20px;
}

.navbar__options {
  display: flex;
  width: 30%;
  padding-right: 5%;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: $font-style-n1;
}

.navbar__icon {
  max-width: 230px;
  img {
    max-width: 100%;
  }
}

.navbar__burger-button{
  background-color: transparent !important;
}

.navbar__burger {
  background-color: $fourth !important;
}

@media only screen and (max-width: 600px) {
  .navbar-burger__header {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    img{
      max-width: 300px;
    }
  }

  .navbar__options {
    display: none !important;
  }

  .navbar-burger__options {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    li {
      margin: 15px 0px;
      font-family: $font-style-n1;
      font-size: $font-size-n2;
    }
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .navbar__options {
    display: none !important;
  }

  .navbar-burger__header {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    img{
      max-width: 300px;
    }
  }

  .navbar-burger__options {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    li {
      margin: 15px 0px;
      font-family: $font-style-n1;
      font-size: $font-size-n2;
    }
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .nav__wrapper {
    ul {
      width: 50%;
      padding-right: 2%;
    }
  }

  .navbar__options {
    display: flex !important;
  }

  .navbar__burger-button{
    display: none !important;
  }

}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .nav__wrapper {
    ul {
      display: flex;
      width: 40%;
      padding-right: 5%;
      flex-flow: row nowrap;
      justify-content: space-between;
      font-family: $font-style-n1;
    }
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
