@import '../../../CommonUI/styles.scss';

.about {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  align-items: center;
  box-sizing: content-box;
  padding-top: 50px;
}

.about__title {
  width: 80%;
  //min-height: 10vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  img {
    max-width: 80px;
  }

  hr {
    margin-top: 20px;
    width: 25%;
  }
}

.about__comments {
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  .comment_one {
    width: 90%;
    //color: $primary;
    line-height: $font-line-height;
    font-family: $font-style-n1;
    font-weight: $font-weight-normal;
    font-size: $font-size-n11;
  }

  .comment_two {
    //font-weight: $font-weight-bold;
    width: 80%;
    text-align: center;
    font-family: $font-style-n0;
    font-weight: $font-weight-normal;
    font-size: $font-size-n2;
  }
}

.object_position_one {
  position: absolute;
  z-index: 0;
  width: 600px;
  left: -200px;
  top: 150px;
}

.object_position_two {
  position: absolute;
  z-index: 0;
  width: 600px;
  right: -100px;
  top: 500px;
}

.object_position_three {
  position: absolute;
  z-index: 0;
  width: 600px;
  right: -400px;
  top: 80px;
}

/* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  .about {
    padding-top: 5px;
  }

  .about__title {
    position: relative;
    margin-bottom: 20px;
    hr{
      margin-top: 10px;
      width: 60%;
    }
  }

  .about__comments {
    width: 80%;
    min-height: 40vh;
    .comment_one {
      width: 100%;
      font-size: $font-size-n6;
      line-height: 30px;
      margin-bottom: 80px;
    }

    .comment_two {
      width: 100%;
      font-size: $font-size-n2;
    }
  }

  .object_position_one {
    position: absolute;
    z-index: 0;
    width: 400px;
    left: -340px;
    top: 100px;
  }

  .object_position_two {
    position: absolute;
    z-index: 0;
    width: 250px;
    right: -50px;
    top: 600px;
  }

  .object_position_three {
    position: absolute;
    z-index: 0;
    width: 400px;
    right: -320px;
    top: -20px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .about__title {
    hr{
      margin-top: 10px;
      margin-bottom: 10px;
      width: 50%;
    }
  }

  .about__comments {
    width: 75%;
    min-height: 40vh;
    .comment_one {
      width: 90%;
      font-size: $font-size-n7;
      line-height: 40px;
      margin-bottom: 20px;
    }

    .comment_two {
      width: 80%;
      font-size: $font-size-n2;
    }
  }

  .object_position_one {
    position: absolute;
    z-index: 0;
    width: 500px;
    left: -380px;
    top: 100px;
  }

  .object_position_two {
    position: absolute;
    z-index: 0;
    width: 350px;
    right: -50px;
    top: 600px;
  }

  .object_position_three {
    position: absolute;
    z-index: 0;
    width: 500px;
    right: -350px;
    top: -20px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .about__title {
    hr{
      margin-top: 10px;
      margin-bottom: 10px;
      width: 50%;
    }
  }

  .about__comments {
    width: 75%;
    min-height: 40vh;
    .comment_one {
      width: 90%;
      font-size: $font-size-n7;
      line-height: 40px;
      margin-bottom: 20px;
    }

    .comment_two {
      width: 80%;
      font-size: $font-size-n2;
    }
  }

  .object_position_one {
    position: absolute;
    z-index: 0;
    width: 500px;
    left: -380px;
    top: 100px;
  }

  .object_position_two {
    position: absolute;
    z-index: 0;
    width: 350px;
    right: -50px;
    top: 600px;
  }

  .object_position_three {
    position: absolute;
    z-index: 0;
    width: 500px;
    right: -350px;
    top: -20px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
/*   .about {
    background-color: aqua;
  }
 */
  .about__title {
    margin-bottom: 25px;
  }

  .about__comments {
    width: 60%;
    min-height: 40vh;
    .comment_one {
      width: 90%;
      font-size: $font-size-n7;
      line-height: 45px;
      margin-bottom: 15px;
    }

    .comment_two {
      width: 80%;
      font-size: $font-size-n2;
    }
  }

  .object_position_one {
    position: absolute;
    z-index: 0;
    width: 500px;
    left: -300px;
    top: 150px;
  }

  .object_position_two {
    position: absolute;
    z-index: 0;
    width: 500px;
    right: -150px;
    top: 400px;
  }

  .object_position_three {
    position: absolute;
    z-index: 0;
    width: 500px;
    right: -380px;
    top: 80px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .about {
    padding-top: 50px;/* 
    background-color: black; */
  }

  .about__title {
    margin-bottom: 50px;
  }

  .about__comments {
    width: 45%;
    min-height: 40vh;
    .comment_one {
      width: 90%;
      font-size: $font-size-n9;
      line-height: 60px;
      margin-bottom: 15px;
    }

    .comment_two {
      width: 80%;
      font-size: $font-size-n2;
    }
  }

  .object_position_one {
    position: absolute;
    z-index: 0;
    width: 600px;
    left: -250px;
    top: 150px;
  }
}
